
import * as storyblokRuntime$vUhD7NayPB from '/nuxt/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxRuntime$2V47jWSGEB from '/nuxt/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 300,
    "sm": 400,
    "md": 600,
    "lg": 900,
    "xl": 1200,
    "xxl": 1800,
    "2xl": 1536,
    "xxxl": 2400
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "moser-stalleinrichtungen.ch"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "png",
    "jpeg",
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$vUhD7NayPB, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$2V47jWSGEB, defaults: {} }
}
        